<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="" elevation="" max-width="">
          <v-card-title class="justify-center"> MODIFICAR LUNA </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <p>
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'inicioPanel' }"
                    >Inicio</router-link
                  >
                  /
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'lunasPanel' }"
                    >Lunas</router-link
                  >
                  /
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{
                      name: 'lunasEditarPanel',
                      params: { idluna: $route.params.idluna },
                    }"
                    >Editar</router-link
                  >
                </p>
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12 text-center" v-show="!mostrarContenido">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="100"
                  :width="7"
                ></v-progress-circular>
              </v-col>
            </v-row>

            <v-form
              @submit.prevent="modificar()"
              v-show="mostrarContenido"
              :disabled="processForm"
            >
              <v-row>
                <v-col cols="12">
                  <v-alert
                    type="error"
                    v-model="alertError"
                    dismissible
                    ref="mensajeError"
                    id="mensajeError"
                  >
                    <ul v-for="(errors, index) in listErrors" :key="index">
                      <li v-text="errors[0]"></li>
                    </ul>
                  </v-alert>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    label="Nombre(*)"
                    v-model="luna.nombre"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <p class="my-0">Descripción:</p>
                  <ckeditor
                    :editor-url="apiUrl('js/ckeditor/ckeditor.js')"
                    v-model="luna.descripcion"
                    :config="{
                      filebrowserBrowseUrl: '/plugin_externos/ckfinder/ckfinder.html',
                      allowedContent: true,
                    }"
                  ></ckeditor>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-select
                    @change="getPosicion()"
                    dense
                    item-text="nombre"
                    item-value="idmodelo_producto"
                    :items="arrayTipoMontura"
                    label="Tipo Montura (*)"
                    outlined
                    v-model="luna.idmodelo_producto"
                  ></v-select>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                    dense
                    label="Titulo Extra"
                    v-model="luna.extra"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                    type="number"
                    min="0"
                    dense
                    label="Precio"
                    v-model="luna.precio"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-select
                    dense
                    item-text="nombre"
                    item-value="valor"
                    :items="arrayPosicion"
                    label="Posición (*)"
                    outlined
                    v-model="luna.posicion"
                  ></v-select>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-select
                    @change="getCategoriaPrecioLuna()"
                    dense
                    item-text="nombre"
                    item-value="idtipo_luna"
                    :items="arrayTipoLuna"
                    label="Tipo Luna"
                    outlined
                    v-model="luna.idtipo_luna"
                  ></v-select>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-select
                    dense
                    item-text="categoria_precio"
                    item-value="idcategoria_precio_luna"
                    :items="arrayCategoriaPrecioLuna"
                    label="Categoria Precio"
                    outlined
                    v-model="luna.idcategoria_precio_luna"
                  ></v-select>
                </v-col>

                <v-col cols="12">
                  <v-select
                    dense
                    item-text="texto"
                    item-value="valor"
                    :items="cmbEstado"
                    label="Estado (*)"
                    outlined
                    v-model="luna.estado"
                  ></v-select>
                </v-col>

                <v-col cols="12" class="text-center">
                  <v-btn
                    class="mr-2"
                    type="submit"
                    :loading="processForm"
                    color="success"
                    large=""
                  >
                    <v-icon left="" large="">mdi-content-save-edit</v-icon> MODIFICAR
                  </v-btn>
                  <v-btn
                    :to="{ name: 'lunasPanel' }"
                    type="button"
                    :loading="processForm"
                    color="error"
                    large=""
                  >
                    <v-icon left="" large="">mdi-close</v-icon> CANCELAR
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      luna: {
        _method: "PUT",
        idluna: "",
        idmodelo_producto: "",
        idtipo_luna: "",
        idcategoria_precio_luna: "",
        nombre: "",
        descripcion: "",
        precio: 0,
        extra: "",
        posicion: "",
        imagen: "",
        estado: 1,
      },
      arrayTipoMontura: [],
      arrayPosicion: [],
      arrayTipoLuna: [],
      arrayCategoriaPrecioLuna: [],
      cmbEstado: [
        {
          texto: "Habilitado",
          valor: 1,
        },
        {
          texto: "Inhabilitado",
          valor: 0,
        },
      ],
      mostrarContenido: false,
      processForm: false,
      listErrors: [],
      alertError: false,
    };
  },

  methods: {
    getPosicion() {
      this.arrayPosicion = [];
      this.axios({
        method: "POST",
        url: "api/panel/lunas/getPosicion",
        data: {
          idmodelo_producto: this.luna.idmodelo_producto,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            for (let i = 1; i <= response.data.data; i++) {
              this.arrayPosicion.push({
                nombre: i,
                valor: i,
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCategoriaPrecioLuna() {
      this.axios({
        method: "POST",
        url: "api/panel/lunas/getCategoriaPrecioLuna",
        data: {
          idtipo_luna: this.luna.idtipo_luna,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            this.arrayCategoriaPrecioLuna = response.data.data;

            if (this.arrayCategoriaPrecioLuna.length == 0) {
              this.luna.idcategoria_precio_luna = "";
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getDatos() {
      this.mostrarContenido = false;
      this.axios({
        method: "GET",
        url: "api/panel/lunas/" + this.$route.params.idluna + "/edit",
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            this.arrayTipoMontura = response.data.data.tipoMontura;
            this.arrayTipoLuna = response.data.data.tipoLuna;

            let luna = response.data.data.luna;

            this.luna.idluna = luna.idluna;
            this.luna.idmodelo_producto = luna.idmodelo_producto;
            this.luna.idtipo_luna = luna.idtipo_luna;
            this.luna.idcategoria_precio_luna = luna.idcategoria_precio_luna;
            this.luna.nombre = luna.nombre;
            this.luna.descripcion = luna.descripcion;
            this.luna.precio = luna.precio;
            this.luna.extra = luna.extra;
            this.luna.posicion = luna.posicion;
            this.luna.imagen = luna.imagen;
            this.luna.estado = luna.estado;

            this.getPosicion();
            this.getCategoriaPrecioLuna();
          }

          this.mostrarContenido = true;
        })
        .catch((error) => {
          this.$toasted.error("Ocurrio un error al buscar este registro", {
            icon: "mdi-close",
          });

          this.$router.push({
            name: "lunasPanel",
          });
        })
        .finally(() => {});
    },

    modificar() {
      this.processForm = true;
      this.alertError = false;
      this.axios({
        method: "POST",
        url: "api/panel/lunas/" + this.luna.idluna,
        data: this.serialize(this.luna),
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            this.$toasted.success(response.data.data, {
              icon: "mdi-check-bold",
            });
            this.$router.push({
              name: "lunasPanel",
            });
          }
        })
        .catch((error) => {
          let status = error.response.status;
          if (status === 422) {
            let arrErrors = error.response.data.errors;
            this.listErrors = Object.values(arrErrors);
            this.alertError = true;
            this.$vuetify.goTo(this.$refs.mensajeError);
          } else {
            this.$toasted.error("Ocurrio un error al modificar este registro", {
              icon: "mdi-close",
            });
          }
        })
        .finally(() => {
          this.processForm = false;
        });
    },
  },
  created() {
    if (!this.validarPermiso("lunas.editar")) {
      this.$toasted.error("Usted no tiene permisos para esta sección", {
        icon: "mdi-close",
      });

      this.$router.push({
        name: "inicioPanel",
      });
    }
    window.scrollTo(0, 0);

    this.getDatos();
  },
};
</script>
